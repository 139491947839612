<template>
    <div class="container-fluid main-color">
        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <h4 class="page-title">New Timeline</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <div class="row">
            <div class="col-lg-12">
                <place-holder v-if="loading"></place-holder>
                <div class="card">
                  <!-- {{ getSelectedProgram() }} -->
                    <div v-if="!loading" class="card-body">
                        <form class="parsley-examples">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row mb-2">
                                        <label
                                            for="level"
                                            class="col-md-3 form-label"
                                        >
                                            Program Name
                                            <span class="text-danger">*</span>
                                        </label>
                                        <div class="col-md-7" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                            <v-select
                                                :disabled="
                                                    currentRouteName ==
                                                    'program-timeline-update'
                                                "
                                                @option:selected="
                                                    getSelectedProgram
                                                "
                                                v-model="selectedProgram"
                                                label="name"
                                                :selectable="
                                                    (options) =>
                                                        isSelectable(options)
                                                "
                                                :options="programs"
                                                :clearable="false"
                                                :class="{
                                                    'p-invalid':
                                                        v$.selectedProgram
                                                            .$error ||
                                                        errorFor('name'),
                                                }"
                                            >
                                            </v-select>
                                            <v-errors
                                                :serverErrors="errorFor('name')"
                                                :vuelidateErrors="{
                                                    errors: v$.selectedProgram
                                                        .$errors,
                                                    value: 'Program Name',
                                                }"
                                            ></v-errors>
                                        </div>

                                        <div class="col-md-7" v-else-if="hasPermissions(['schedule-program-timeline-list'])">
                                            <v-select disabled
                                                v-model="selectedProgram"
                                                label="name"
                                                :clearable="false"
                                            >
                                            </v-select>
                                        </div>

                                    </div>

                                    <div class="row mb-2">
                                        <label
                                            for="short_name"
                                            class="col-md-3 form-label"
                                        >
                                            Program Code
                                        </label>
                                        <div class="col-md-7">
                                            <input
                                                type="text"
                                                id="short_name"
                                                name="short_name"
                                                class="form-control"
                                                placeholder="Program Short Name"
                                                v-model="
                                                    timeline.program_short_name
                                                "
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row col-md-4">
                                    <div
                                        class="row mb-2"
                                        v-if="
                                            currentRouteName ===
                                            'program-timeline-update'
                                        "
                                    >
                                        <label
                                            for="status"
                                            class="col-md-4 form-label"
                                        >
                                            Status
                                            <!-- <span class="text-danger">*</span> -->
                                            <!-- :disabled="
                        currentRouteName == 'trainer-update' ? false : true
                      " -->
                                        </label>
                                        <div class="col-md-7" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                            <select
                                                name="status"
                                                v-model="status"
                                                class="form-control"
                                            >
                                                <option :value="1">
                                                    Active
                                                </option>
                                                <option :value="0">
                                                    Inactive
                                                </option>
                                            </select>
                                        </div>

                                        <div class="col-md-7" v-else-if="hasPermissions(['schedule-program-timeline-list'])">
                                            <select
                                                name="status"
                                                v-model="status"
                                                disabled
                                                class="form-control"
                                            >
                                                <option :value="1">
                                                    Active
                                                </option>
                                                <option :value="0">
                                                    Inactive
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row mb-4" v-if="status == 0">
                                        <label
                                            for="remark"
                                            class="col-md-4 form-label"
                                        >
                                            Remark
                                            <!-- <span class="text-danger">*</span> -->
                                        </label>
                                        <div class="col-md-7" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                            <textarea
                                                type="text"
                                                id="remark"
                                                name="remark"
                                                class="form-control"
                                                placeholder="Remark"
                                                v-model="remark"
                                                :class="{
                                                    'p-invalid':
                                                        v$.remark.$error ||
                                                        errorFor('remark'),
                                                }"
                                            >
                                            </textarea>
                                            <v-errors
                                                :serverErrors="
                                                    errorFor('remark')
                                                "
                                                :vuelidateErrors="{
                                                    errors: v$.remark.$errors,
                                                    value: 'Remark',
                                                }"
                                            ></v-errors>
                                        </div>

                                        <div class="col-md-7" v-else-if="hasPermissions(['schedule-program-timeline-list'])">
                                            <textarea
                                                type="text"
                                                id="remark"
                                                name="remark"
                                                class="form-control"
                                                placeholder="Remark"
                                                v-model="remark"
                                                disabled
                                            >
                                            </textarea>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="float-end" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                <button
                                    v-show="!loading"
                                    type="button"
                                    @click="addTimeline()"
                                    class="btn btn-sm btn-blue waves-effect waves-light mb-2"
                                >
                                    <i class="mdi mdi-plus-circle"></i> Add
                                </button>
                            </div>

                            <div
                                class="progress mt-5"
                                style="
                                    height: 0.5px;
                                    background-color: #ccc !important;
                                "
                            >
                                <div
                                    class="progress-bar"
                                    role="progressbar"
                                ></div>
                            </div>

                            <div class="row mt-2 text-center">
                                <div class="d-flex">
                                    <div class="col-md-3">
                                        <label for="name" class="form-label">
                                            Level
                                            <span class="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="name" class="form-label">
                                            Type
                                            <span class="text-danger">*</span>
                                        </label>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="name" class="form-label">
                                            Month
                                        </label>
                                    </div>
                                    <div class="col-md-1">
                                        <label for="name" class="form-label">
                                            Week
                                        </label>
                                    </div>
                                    <div class="col-md-3">
                                        <label for="name" class="form-label">
                                            Day
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row mb-4" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                <div
                                    v-for="(
                                        item, index
                                    ) in timeline.program_timeline"
                                    :key="index"
                                    class="d-flex m-2"
                                >
                                    <div class="col-md-2 me-2">
                                        <v-select
                                            v-model="item.level"
                                            label="name"
                                            :options="levels"
                                            :selectable="(options) => isSelectableLevel(options, index)"
                                            :reduce="(name) => name.name"
                                            :clearable="false"
                                        >
                                        </v-select>
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <v-select
                                            v-model="item.type"
                                            label="name"
                                            :options="types"
                                            :selectable="(options) => isSelectableType(options, index)"
                                            :reduce="(name) => name.name"
                                            :clearable="false"
                                        >c
                                        </v-select>
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="month"
                                            name="month"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Month"
                                            v-model="item.month"
                                        />
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="week"
                                            name="week"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Week"
                                            v-model="item.week"
                                        />
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="day"
                                            name="day"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Day"
                                            v-model="item.day"
                                        />
                                    </div>
                                    <div class="col-md-2" v-show="index != 0">
                                        <div
                                            v-show="!loading"
                                            @click="removeTimeline(index)"
                                        >
                                            <i
                                                class="fas fa-trash-alt mx-1"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                                  <div class="row mb-4" v-else-if="hasPermissions(['schedule-program-timeline-list'])">
                                <div
                                    v-for="(
                                        item, index
                                    ) in timeline.program_timeline"
                                    :key="index"
                                    class="d-flex m-2"
                                >
                                    <div class="col-md-2 me-2">
                                        <v-select
                                            v-model="item.level"
                                            label="name"
                                            disabled
                                            :clearable="false"
                                        >
                                        </v-select>
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <v-select
                                            v-model="item.type"
                                            label="name"
                                            disabled
                                            :clearable="false"
                                        >
                                        </v-select>
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="month"
                                            name="month"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Month"
                                            v-model="item.month"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="week"
                                            name="week"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Week"
                                            v-model="item.week"
                                            disabled
                                        />
                                    </div>
                                    <div class="col-md-2 me-2">
                                        <input
                                            type="number"
                                            id="day"
                                            name="day"
                                            class="form-control"
                                            oninput="this.value = !!this.value && Math.abs(this.value) >= 1 ? Math.abs(this.value) : null"
                                            placeholder="Day"
                                            disabled
                                            v-model="item.day"
                                        />
                                    </div>
                                    <div class="col-md-2" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                        <div
                                            v-show="!loading"
                                            @click="removeTimeline(index)"
                                        >
                                            <i
                                                class="fas fa-trash-alt mx-1"
                                            ></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- end row -->
                            <div class="row" v-if="hasPermissions(['schedule-program-timeline-create', 'schedule-program-timeline-edit'])">
                                <div class="col-md-12">
                                    <div class="text-center mt-3 mb-3">
                                        <router-link
                                            :to="{ name: 'program-timeline' }"
                                        >
                                            <button
                                                type="button"
                                                class="btn w-sm btn-secondary me-5"
                                            >
                                                Cancel
                                            </button>
                                        </router-link>
                                        <button
                                            :disabled="isLoading ? true : false"
                                            type="button"
                                            class="btn w-sm btn-success waves-effect waves-light px-3"
                                            @click="
                                                currentRouteName ===
                                                'program-timeline-update'
                                                    ? updateTimeline()
                                                    : createTimeline()
                                            "
                                        >
                                            <span
                                                v-if="isLoading"
                                                class="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                            {{
                                                isLoading == true
                                                    ? "Loading..."
                                                    : "Save"
                                            }}
                                        </button>
                                    </div>
                                </div>
                                <!-- end col -->
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required, requiredIf } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
    setup() {
        const toast = useToast();
        return { v$: useVuelidate(), toast };
    },
    components: {
        vSelect,
    },
    mixins: [validationErrors, userHasPermissions],
    data() {
        return {
            programs: [],
            levels: [],
            types: [
                {
                    id: 1,
                    name: "Orientation",
                },
                {
                    id: 2,
                    name: "InClass",
                },
                {
                    id: 3,
                    name: "OJT",
                },
            ],
            timeline: {
                program_id: "",
                program_name: "",
                program_short_name: "",
                program_timeline: [
                    {
                        level: "",
                        type: "",
                        month: "",
                        week: "",
                        day: "",
                    },
                ],
            },
            existedEmployee: [],
            selectedProgram: null,
            status: "1",
            remark: "",
            baseUrl: process.env.VUE_APP_BASE_URL,
            baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
            loading: false,
            isLoading: false,
        };
    },
    validations() {
        return {
            selectedProgram: { required },
            timeline: {
                program_id: { required },
                program_name: { required },
                program_short_name: { required },
            },
            remark: {
                required: requiredIf(() => {
                    return this.status != 1;
                }),
            },
        };
    },
    methods: {
        addTimeline() {
            this.timeline.program_timeline.push({
                level: "",
                type: "",
                month: "",
                week: "",
                day: "",
            });
        },
        removeTimeline(index) {
            this.timeline.program_timeline.splice(index, 1);
        },
        getSelectedProgram() {
            if (this.selectedProgram) {
                this.timeline.program_id = this.selectedProgram.id;
                this.timeline.program_name = this.selectedProgram.name;
                this.timeline.program_short_name =
                this.selectedProgram.short_name;
            }
            
        },
        async getPrograms() {
            this.loading = true;
            await axios
                .get(`${this.baseUrlHRIS}api/programs`)
                .then((response) => {
                    this.programs = response.data.data;
                    this.loading = false;
                })
                .catch(() => {
                    this.toast.error("Not Found Program!");
                });
        },
        async getAllLevels() {
            this.loading = true;
            await axios
                .get(`${this.baseUrl}admin/v1/levels`)
                .then((response) => {
                    this.levels = response.data.data;
                    this.loading = false;
                });
        },
        async getTimelineById() {
            this.loading = true;
            await axios
                .get(
                    `${this.baseUrl}admin/v2/program-timeline/${this.$route.params.id}`
                )
                .then((response) => {
                    const result = response.data.data;
                    this.timeline = result;
                    this.selectedProgram = {
                        id: result.program_id,
                        name: result.program_name,
                        short_name: result.program_short_name,
                    };
                    this.timeline.program_timeline =
                        result.program_timeline_details.map((item) => {
                            return {
                                level: item.level,
                                type: item.type,
                                month: item.month,
                                week: item.week,
                                day: item.day,
                            };
                        });
                    this.status = result.status;
                    this.remark = result.remark;
                })
                .catch(() => {
                    this.toast.error("Not Found Data!");
                });
            this.loading = false;
        },

        checkEmptyValue(array) {
            // let timelineData = Object.values(array);
            if (
                array.level == null ||
                array.type == null ||
                array.level == "" ||
                array.type == ""
            ) {
                return false;
            } else {
                return true;
            }
        },

        async createTimeline() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;

            let checkTimeline = [];
            this.timeline.program_timeline.forEach((array) => {
                let timeline_data = this.checkEmptyValue(array);
                checkTimeline.push(timeline_data);
            });
            if (checkTimeline.includes(false)) {
                this.toast.error("Please Fill All Input!");
                return;
            }

            this.isLoading = true;

            await axios
                .post(`${this.baseUrl}admin/v2/program-timeline`, this.timeline)
                .then(() => {
                    this.$router.push({ name: "program-timeline" });
                    this.toast.success("Successfully Created Timeline!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },

        async updateTimeline() {
            const isFormCorrect = await this.v$.$validate();
            if (!isFormCorrect) return;

            let checkTimeline = [];
            this.timeline.program_timeline.forEach((array) => {
                let timeline_data = this.checkEmptyValue(array);
                checkTimeline.push(timeline_data);
            });
            if (checkTimeline.includes(false)) {
                this.toast.error("Please Fill All Input!");
                return;
            }
            if (this.status == 1) this.remark = "";
            let data = {
                program_id: this.timeline.program_id,
                program_name: this.timeline.program_name,
                program_short_name: this.timeline.program_short_name,
                program_timeline: this.timeline.program_timeline,
                status: this.status,
                remark: this.remark,
            };
            this.isLoading = true;
            axios
                .put(
                    `${this.baseUrl}admin/v2/program-timeline/${this.$route.params.id}`,
                    data
                )
                .then(() => {
                    this.$router.push({ name: "program-timeline" });
                    this.toast.success("Successfully Timeline Updated!");
                    this.isLoading = false;
                })
                .catch((error) => {
                    this.errors = error.response.data.errors;
                    this.toast.error("Something Went Wrong!");
                    this.isLoading = false;
                });
        },

        async getExistedEmployee() {
            await axios
                .get(`${this.baseUrl}admin/v2/program-timeline`)
                .then((response) => {
                    this.existedEmployee = response.data.data;
                })
                .catch((error) => {
                    this.error = error.response;
                    this.toast.error("Something went wrong.");
                });
        },

        isSelectable(options) {
            if (this.selectedProgram) {
                // Check if the program with the same id exists in existedEmployee
                const programExists = this.existedEmployee.some(
                    (item) => item.program_id === this.selectedProgram.id
                );
          
                // If the program doesn't exist, add it to existedEmployee
                if (!programExists) {
                    this.existedEmployee = this.existedEmployee.filter(
                        (item) => !item.is_selected
                    );
                    this.existedEmployee.push({
                        program_id: this.selectedProgram.id,
                        program_name: this.selectedProgram.name,
                        is_selected: true,
                    });
                }
            }
            // Now, check if the options.id exists in existedEmployee
            return !this.existedEmployee.some(
                (item) => item.program_id === options.id
            );
        },
        isSelectableType(options, index) {
      if(!this.timeline.program_timeline[index].type || !options) return true;
      return this.timeline.program_timeline[index].type != options.name;
    },
    isSelectableLevel(options, index) {
      if(!this.timeline.program_timeline[index].level || !options) return true;
      return this.timeline.program_timeline[index].level != options.name;
    },
    },
    created() {
        this.getPrograms();
        this.getAllLevels();
        if (this.currentRouteName == "program-timeline-update") {
            this.getTimelineById();
        }
        this.getExistedEmployee();
    },
    computed: {
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style>
.custom-selectCSS {
    --vs-dropdown-min-width: 160px;
    --vs-dropdown-max-height: 200px;
}
</style>
